export default {
	name: "DoctorVerified",
	data() {
		return {
			//医生userId
			getDoctorManageUuid: null,
			tableData:[],
			// imageUrl:'http://smartv.koala18.com/',
			imageUrl:'http://112.124.13.65/',
			srcList:[],

			isEdit: ''
		}
	},
	created() {
		this.getDoctorManageUuid = this.$route.query.getDoctorManageUuid;
		this.isEdit = this.$route.query.isEdit;
		this.getDoctorVerified();
		console.log(this.srcList)
	},
	methods: {
		FailPassVerifiedy(uuid){
			console.log(uuid)
			this.$confirm("您确定通过审核么?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				lockScroll: false,
				type: "warning"
			}).then(() => {
				this.$axios.get('/api/sys/web/doctor/isVerified/' + uuid + "/" + 1).then(res => {
					this.$message.success({
						message: res.data.message,
					});
					this.$router.push({path: 'doctor-list'});
				}).catch(error => {
					console.log(error);
					//this.$message.error("请求失败");
				});
			}).catch(() => {});
		},
		PassVerifiedy(uuid){
			console.log(uuid)
			this.$confirm("您确定不通过审核么?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				lockScroll: false,
				type: "warning"
			}).then(() => {
				this.$axios.get('/api/sys/web/doctor/isVerified/' + uuid + "/" + '-1').then(res => {
					this.$message.success({
						message: res.data.message,
					});
					this.$router.push({path: 'doctor-list'});
				}).catch(error => {
					console.log(error);
					//this.$message.error("请求失败");
				});
			}).catch(() => {});
		},
		//获取未审核医生信息
		getDoctorVerified() {
			this.$axios
				.get('/api/sys/web/doctor/detail/'+this.getDoctorManageUuid)
				.then(response => {
					if (response.data.code === 200) {
						this.tableData=response.data.data;
						this.tableData.regisCertificate =this.imageUrl + this.tableData.regisCertificate
						this.tableData.docCertificate =this.imageUrl + this.tableData.docCertificate
						this.srcList[0] = this.tableData.regisCertificate;
						this.srcList[1] = this.tableData.docCertificate
					}
				})
				.catch(e => {
					console.log(e);
					/* this.$message({
						message: "请求失败",
						type: "error"
					}); */
				});
		},
	}
}
